export default [
    {
        headerName: 'Id',
        field: 'id',
        width: 350,
        sortable: false,
        initialHide: true,
    },
    {
        headerName: 'Name',
        field: 'name',
        width: 220,
    },
    {
        headerName: 'Display Name',
        field: 'displayName',
        width: 220,
    },
    {
        headerName: 'State',
        field: 'state',
    },
    {
        headerName: 'County',
        field: 'county',
    },
    {
        headerName: 'Status',
        field: 'status',
    },
    {
        headerName: 'Address',
        field: 'street',
    },
    {
        headerName: 'Zip',
        field: 'zipCode',
    },
    {
        headerName: 'City',
        field: 'city',
    },
    {
        headerName: 'NCES Id',
        field: 'ncesId',
    },
    {
        headerName: 'SF Account Number',
        field: 'sfAccountNumber',
    },
    {
        headerName: 'CRM Account Number',
        field: 'crmAccountNumber',
    },
    {
        cellRendererFramework: 'RowActionButtons',
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['id'],
                    routeName: 'client-view',
                    role: 'ROLE_IMT_CLIENTS_VIEW',
                    btnText: 'View',
                    icon: 'fal fa-eye',
                },
                {
                    routerLinkIds: ['id'],
                    routeName: 'client-edit',
                    role: 'ROLE_IMT_CLIENTS_EDIT',
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                },
                {
                    routerLinkIds: ['id'],
                    routeName: 'imt-authentications',
                    role: 'ROLE_IMT_AUTHENTICATIONS_VIEW',
                    btnText: 'Auth',
                    icon: 'fal fa-lock',
                },
                {
                    routerLinkIds: ['id'],
                    routeName: 'accounts-list',
                    role: 'ROLE_ACCOUNT_SERVICE_VIEW',
                    btnText: 'Accounts',
                    icon: 'fal fa-users',
                },
            ],
        },
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
