<template>
    <child-layout title="Client Management">
        <ag-grid-server-side
            id="clientMgmt"
            :server-side-datasource="serverSideDatasource"
            :column-defs="columnDefs"
            :show-download-button="false"
        >
            <template v-slot:controls>
                <v-btn
                    id="create-client-btn"
                    :to="{ name: 'client-create' }"
                    :disabled="!hasCreateRole"
                    class="primary ml-4"
                >
                    Create Client
                </v-btn>
                <div class="button-space"></div>
                <!-- Add the "Export CSV" button -->
                <ExportToCSVButton
                    :fetch-all-data="fetchAllClientData"
                    filename="ClientsExport.csv"
                />
            </template>
        </ag-grid-server-side>
    </child-layout>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/imt/clients'
import pagination from '@/helpers/ag-grid/pagination/rest'
import ChildLayout from '@/components/layout/ChildLayout'
import { ROLE_IMT_CLIENTS_CREATE } from '@/helpers/security/roles'
import { mapGetters, mapActions } from 'vuex'
import ExportToCSVButton from '@/components/table/ExportToCSVButton'

export default {
    name: 'IMTClients',
    components: {
        ChildLayout,
        AgGridServerSide,
        ExportToCSVButton,
    },
    data: () => ({
        columnDefs: ColumnDef,
        currentId: null,
        currentParams: null,
    }),
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            items: 'client/getItems',
            metadata: 'client/getMetadata',
        }),
        hasCreateRole() {
            return this.hasRole(ROLE_IMT_CLIENTS_CREATE)
        },
    },
    methods: {
        ...mapActions({
            fetchClient: 'client/get',
            fetchClients: 'client/cget',
        }),
        serverSideDatasource: function () {
            const fetch = async ({ id, ...params }) => {
                //clear currentId and currentParams
                this.currentId = null
                this.currentParams = null
                if (id) {
                    this.currentId = id
                    // fetch client by id
                    await this.fetchClient(id)
                    return { items: this.items, count: this.items.length }
                }

                this.currentParams = params
                // fetch clients by filter params
                await this.fetchClients({ params })
                return { items: this.items, count: this.metadata.count }
            }

            return {
                getRows: async function (params) {
                    const { items, count } = await fetch(pagination(params))
                    params.successCallback(items, count)
                },
            }
        },
        // Fetch all data and update exportData
        async fetchAllClientData() {
            let dataItems = []
            let paramVal = this.currentParams
            // After fetching the data, update exportData
            if (this.currentId) {
                const apiResponse = await this.fetchClient(this.currentId)
                return dataItems.concat(apiResponse)
            } else {
                const loopCount = Math.ceil(this.metadata.count / this.currentParams.size)

                for (let i = 1; i <= loopCount; i++) {
                    if (paramVal && paramVal.hasOwnProperty('page')) {
                        paramVal.page = i

                        const updatedParams = paramVal
                        const clientsApiResponse = await this.fetchClients({
                            params: updatedParams,
                        })

                        dataItems = dataItems.concat(clientsApiResponse)
                    }
                }

                return dataItems
            }
        },
    },
}
</script>

<style scoped>
.button-space {
    margin-left: 5px;
}
</style>