/**
 * @param params
 * @returns {{size: *, page: number}}
 */
export default function (params) {
    const sortModel = params.request.sortModel
    const filterModel = params.api.getFilterModel()

    // check if filtering by id
    const id = filterModel?.id?.filter
    if (id) {
        return { id: id.trim() }
    }

    let filterObj = {}
    Object.entries(filterModel).forEach((object) => {
        filterObj[`search[${object[0]}]`] = object[1].filter
    })

    let sortObject = {}
    if (sortModel.length > 0) {
        const sortColumn = sortModel[0].colId
        const sortDirection = sortModel[0].sort
        sortObject = {
            [`sort[${sortColumn}]`]: sortDirection,
        }
    }

    const size = params.api.gridOptionsWrapper.gridOptions.cacheBlockSize
    const page = params.request.endRow / size || 1

    return {
        page,
        size,
        ...sortObject,
        ...filterObj,
    }
}
