<template>
    <v-btn color="primary" :loading="exportProcessing" @click="fetchAndExportData">
        Export CSV</v-btn
    >
</template>

<script>
import { parse } from 'json2csv'
import { saveFile } from '@/helpers/file'
import { warningSnackbar, errorSnackbar } from '@/helpers/snackbar'

export default {
    name: 'ExportToCSVButton',
    props: {
        fetchAllData: {
            type: Function, // Define it as a function prop
            required: true,
            default: () => {},
        },
        filename: {
            type: String,
            default: 'ExportData.csv', //Default filename
        },
    },
    data: () => ({
        exportProcessing: false,
    }),
    methods: {
        async fetchAndExportData() {
            this.exportProcessing = true
            try {
                // Fetch data using the function from Parent
                const exportData = await this.fetchAllData()

                // Check if exportData is empty
                if (!exportData || exportData.length === 0) {
                    // Show a warning snackbar if exportData is empty
                    warningSnackbar({ subtext: 'No data to export' })
                    return
                }

                // Export the CSV using the fetched data
                this.exportCSV(exportData)
            } catch (error) {
                console.error('Fetch and export error:', error)
                errorSnackbar({ subtext: 'Error fetching and exporting data' })
            } finally {
                this.exportProcessing = false
            }
        },
        exportCSV(data) {
            try {
                // parse the csv using json2csv library
                const csv = parse(data)
                // use saveFile helper to save the csv file
                saveFile(this.filename, csv)
            } catch (error) {
                console.error('Export to CSV error:', error)
                errorSnackbar({ subtext: 'Error exporting CSV' })
            }
        },
    },
}
</script>